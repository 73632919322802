import React, { createContext, useState } from "react";

export const AppContext = createContext();

export function AppProvider({ children }) {
  const [isDarkMode, setIsDarkMode] = useState(false); // 다크모드 상태
  const [isFullscreen, setIsFullscreen] = useState(false); // 전체모드 상태

  const toggleDarkMode = () => setIsDarkMode((prev) => !prev); // 다크모드 토글
  const toggleFullscreen = () => setIsFullscreen((prev) => !prev); // 전체모드 토글

  const enterFullscreen = () => setIsFullscreen(true);
  const exitFullscreen = () => setIsFullscreen(false);

  return (
    <AppContext.Provider
      value={{
        isDarkMode,
        toggleDarkMode,
        isFullscreen,
        toggleFullscreen,
        enterFullscreen,
        exitFullscreen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
